import { Trans } from '@lingui/macro';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import styled from 'styled-components';

import commonPropTypes from 'utils/commonPropTypes';
import capitalizedTranslation from 'utils/i18n';

import * as svars from 'assets/style/variables';

import HelpcrunchHelpTooltip from '../navigation/HelpcrunchHelpTooltip';

const StyledFormFieldContainer = styled(Form.Field)`
  display: inline-flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-bottom: ${({ nomargin }) =>
    nomargin ? '0' : `calc(${svars.spaceNormalLarge} + 10px)`};
`;

const StyledLabel = styled.label`
  display: flex;
  margin: auto 0;
  margin-right: ${svars.spaceMedium};
`;

function InlineFormField({ labelId, input, help, noMargin }) {
  return (
    <StyledFormFieldContainer nomargin={noMargin ? '1' : null}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <StyledLabel>
          <Trans id={labelId} render={capitalizedTranslation} />
          {help && (
            <HelpcrunchHelpTooltip
              smallTooltip
              articleId={67}
              helpText={help}
              style={{
                marginLeft: svars.spaceSmall,
                paddingTop: svars.spaceSmaller,
              }}
            />
          )}
        </StyledLabel>
      </div>
      {input}
    </StyledFormFieldContainer>
  );
}

InlineFormField.propTypes = {
  labelId: PropTypes.string.isRequired,
  input: PropTypes.node.isRequired,
  help: commonPropTypes.i18nText,
  noMargin: PropTypes.bool,
};

InlineFormField.defaultProps = {
  help: null,
  noMargin: false,
};

export default InlineFormField;
