import { useCallback, useEffect, useMemo, useState } from 'react';
import { DayPicker, useDayPicker } from 'react-day-picker';

import { msg } from '@lingui/macro';
import { max as getMaxDate, parse, startOfMonth } from 'date-fns';
import { capitalize } from 'lodash';
import PropTypes from 'prop-types';
import { Icon, Label, Popup } from 'semantic-ui-react';
import styled from 'styled-components';

import { fnsLocales } from 'actions/locale';
import { formatDate } from 'reducers/locale';

import { LightLargeHeader } from 'components/ui/Header';
import InTextDropdown from 'components/ui/inputs/InTextDropdown';
import { TextInput } from 'components/ui/inputs/TextInput';

import commonPropTypes from 'utils/commonPropTypes';
import { range } from 'utils/helpers';
import { useOnClickOutside } from 'utils/hooks';

import * as svars from 'assets/style/variables';

// eslint-disable-next-line import/no-unresolved
import 'react-day-picker/style.css';

const INPUT_DATE_FORMAT = 'P';
const INPUT_WIDTH = '135px';

const StyledInput = styled(TextInput)`
  & * {
    cursor: pointer;
  }
  &&&& {
    padding: 0;
    text-align: center;
    background: transparent;
    // Make sure "Date maximum" as placeholder fits
    /* min-width: ${INPUT_WIDTH}; */
    // Override default max-width
    /* max-width: ${INPUT_WIDTH}; */
    max-width: none;

    & input {
      max-width: ${INPUT_WIDTH};
    }
    & .label {
      background: ${svars.colorWhite};
      border: ${svars.lightBorderStyle};
      border-left: none;
      /* Set left border radiuses only to 0 */
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
      ${({ labeldisabled }) =>
        labeldisabled
          ? `border-color: ${svars.colorLightGrey}; color: ${svars.colorLightGrey};`
          : svars.hoverClickableCss}
    }
    ${({ error }) =>
      error
        ? ` &&& {
    color: ${svars.colorDanger};
    border-color: ${svars.colorDanger};
  }
  `
        : ''}
  }
`;
export const StyledDayPicker = styled(DayPicker)`
  transition: ${svars.transitionBase};
  .rdp-button_next,
  .rdp-button_previous {
    /* padding: ${svars.spaceNormal}; */
    transition: ${svars.transitionBase};
    &:hover {
      background: ${svars.accentColorLighter};
    }
    border-radius: 30px;
  }
  .rdp-button_next:disabled,
  .rdp-button_previous:disabled {
    background: inherit;
  }

  .rdp-day_button {
    transition: ${svars.transitionBase};
    :hover {
      background: ${svars.accentColorLighter};
    }
  }

  .rdp-month_grid {
    margin: 0 ${svars.spaceSmall};
  }

  .rdp-selected .rdp-day_button {
    background: var(--rdp-accent-color);
    color: white;
    border-radius: 20%;
  }
  min-height: 315px;
  margin: auto;
  --rdp-cell-size: ${({ cellSize }) => cellSize || '33px'};
  --rdp-today-color: ${svars.accentColor};
  --rdp-accent-color: ${({ error }) => (error ? 'red' : svars.colorPrimary)};
  --rdp-background-color: #e7edff;
  /* Outline border for focused elements */
  --rdp-outline: 2px solid var(--rdp-accent-color);
  /* Outline border for focused and selected elements */
  --rdp-outline-selected: 2px solid rgba(0, 0, 0, 0.75);

  & .rdp-caption_label {
    margin-left: ${svars.spaceNormal};
    font-weight: ${svars.fontWeightSemiBold};
  }

  & .rdp-table {
    margin: ${svars.spaceNormal};
  }
`;

const StyledHeader = styled(LightLargeHeader)`
  &&& {
    margin: ${svars.spaceSmaller} auto auto ${svars.spaceNormal};
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: ${svars.spaceSmall} 0;
    min-height: var(--rdp-nav-height);
    border-bottom: solid ${svars.colorLighterGrey};
  }
`;

const StyledYearSpan = styled.span`
  padding-left: ${svars.spaceNormal};
  color: ${svars.accentColor};
`;

const StyledPopup = styled(Popup)`
  min-height: 370px;
  background: ${({ error }) => (error ? svars.colorDangerLightest : undefined)};
  color: ${({ error }) => (error ? svars.colorDanger : svars.fontColorBase)};
`;

export function CustomDayPickerCaption({
  calendarMonth: { date: displayMonth },
}) {
  const {
    dayPickerProps: { startMonth, endMonth },
    goToMonth,
  } = useDayPicker();
  const authorizedYears = useMemo(() =>
    range(
      startMonth?.getFullYear() || 1900,
      (endMonth || new Date('2200-01-01')).getFullYear() + 1
    ).map(
      (it) => ({
        key: it,
        text: `${it}`,
        value: it,
      }),
      [startMonth, endMonth]
    )
  );
  const goToYear = useCallback(
    (e, { value }) => {
      const targetDate = new Date(displayMonth).setFullYear(value);
      goToMonth(startMonth ? getMaxDate([targetDate, startMonth]) : targetDate);
    },
    [displayMonth, goToMonth]
  );
  const onlyOneYear = !authorizedYears || authorizedYears.length === 1;
  return (
    <StyledHeader>
      <span>
        {capitalize(formatDate(displayMonth, 'MMMM'))}
        {onlyOneYear ? (
          <StyledYearSpan>{displayMonth.getFullYear()}</StyledYearSpan>
        ) : (
          <InTextDropdown
            options={authorizedYears}
            onChange={goToYear}
            value={displayMonth.getFullYear()}
            style={{
              paddingLeft: svars.spaceNormal,
            }}
          />
        )}
      </span>
    </StyledHeader>
  );
}

CustomDayPickerCaption.propTypes = {
  calendarMonth: PropTypes.shape({
    date: PropTypes.instanceOf(Date),
  }).isRequired,
};

const getDateISOString = (date) => {
  if (!date) return date;
  const offsetDate = new Date(
    date.getTime() - date.getTimezoneOffset() * 60000
  );
  return offsetDate.toISOString().split('T')[0];
};

function DaySelect({
  selected,
  onSelect,
  footer,
  placeholder,
  error,
  disabled,
  fromDate,
  toDate,
  onTogglePopup,
  layout,
  ...otherProps
}) {
  const [popupIsOpen, setPopupIsOpen] = useState(false);
  const togglePopup = useCallback(() => {
    if (!disabled) {
      setPopupIsOpen(!popupIsOpen);
      if (onTogglePopup) onTogglePopup(!popupIsOpen);
    }
  }, [popupIsOpen, disabled, onTogglePopup]);
  const popupRef = useOnClickOutside(() => popupIsOpen && togglePopup());
  const pickerHidden = useMemo(() => {
    const hidden = {};
    if (fromDate) hidden.before = fromDate;
    if (toDate) hidden.after = toDate;
    return [hidden];
  }, [fromDate, toDate]);

  // Hold the month in state to control the calendar when the input changes
  const [month, setMonth] = useState(toDate || new Date());

  // Hold the input value in state
  const [inputValue, setInputValue] = useState(
    selected ? formatDate(selected, INPUT_DATE_FORMAT) : ''
  );
  useEffect(() => {
    if (selected === null) {
      setInputValue('');
    } else if (selected) {
      setInputValue(formatDate(selected, INPUT_DATE_FORMAT));
    }
  }, [selected]);

  const handleDayPickerSelect = useCallback(
    (date) => {
      if (!date) {
        setInputValue('');
        onSelect(null);
      } else {
        onSelect(getDateISOString(date));
        setMonth(date);
        setInputValue(formatDate(date, INPUT_DATE_FORMAT));
      }
      togglePopup();
    },
    [setInputValue, onSelect, setMonth, togglePopup]
  );

  /**
   * Handle the input change event: parse the input value to a date, update the
   * selected date and set the month.
   */
  const handleInputChange = useCallback(
    ({ target: { value } }) => {
      if (!value) {
        onSelect(null);
        // setMonth(new Date());
        setInputValue('');
      } else {
        setInputValue(value); // keep the input value in sync
        if (/^\d{2}\/\d{2}\/\d{4}$/.test(value)) {
          const parsedDate = parse(value, 'dd/MM/yyyy', new Date());
          onSelect(getDateISOString(parsedDate));
          setMonth(parsedDate);
        }
      }
    },
    [setInputValue, onSelect, setMonth]
  );

  const resetSelectedValue = useCallback(() => {
    onSelect(null);
    // We need to manually call onChange on input to clear it
    handleInputChange({ value: '', target: { value: '' } });
  }, [onSelect, handleInputChange]);
  const dateDisabled =
    fromDate || toDate ? { before: fromDate, after: toDate } : null;
  return (
    <span
      style={{
        flexDirection: layout,
        alignItems: layout === 'row' ? 'center' : 'flex-start',
      }}
    >
      <StyledPopup
        on="click"
        open={!disabled && popupIsOpen}
        flowing
        error={error}
        trigger={
          <span>
            <StyledInput
              type="text"
              value={inputValue}
              onChange={handleInputChange}
              error={error}
              disabled={disabled}
              labeldisabled={!selected && !inputValue ? '1' : null}
              onClick={togglePopup}
              icon="calendar"
              iconPosition="left"
              label={
                <Label onClick={resetSelectedValue}>
                  <span>
                    <Icon name="delete" style={{ margin: 0 }} />
                  </span>
                </Label>
              }
              labelPosition="right"
              placeholder={placeholder}
              clearable
            />
          </span>
        }
      >
        <span ref={popupRef}>
          <StyledDayPicker
            locale={fnsLocales[window.__locale__]}
            month={month}
            onMonthChange={setMonth}
            selected={selected}
            onSelect={handleDayPickerSelect}
            components={{
              MonthCaption: CustomDayPickerCaption,
            }}
            error={error}
            mode="single"
            startMonth={fromDate ? startOfMonth(fromDate) : null}
            endMonth={toDate ? startOfMonth(toDate) : null}
            disabled={dateDisabled}
            hidden={pickerHidden}
            footer={footer}
            {...otherProps}
          />
        </span>
      </StyledPopup>
    </span>
  );
}

DaySelect.propTypes = {
  selected: PropTypes.shape({ toLocaleDateString: PropTypes.func }),
  onSelect: PropTypes.func.isRequired,
  footer: PropTypes.string,
  placeholder: PropTypes.string,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  fromDate: commonPropTypes.date,
  toDate: commonPropTypes.date,
  // Triggers when the dayselect popup is opened or closed
  onTogglePopup: PropTypes.func,
  layout: PropTypes.oneOf(['row', 'column']),
  resetI18nText: commonPropTypes.i18nText,
};
DaySelect.defaultProps = {
  footer: null,
  placeholder: formatDate(new Date(), INPUT_DATE_FORMAT),
  selected: undefined,
  error: false,
  disabled: false,
  fromDate: new Date('2017-01-01'),
  toDate: new Date(),
  onTogglePopup: undefined,
  layout: 'row',
  resetI18nText: msg({ id: 'reset' }),
};
export default DaySelect;
